<template>
  <div v-if="source.canAccess">
    <main class="virt-grid-row pa-3" :style="source.confirmed || source.disabled ? 'background-color: whitesmoke' : 'background-color: white'">
      <v-btn v-if="computedClockIns.some(ci => ci.id > 0 && !ci.confirmed)"
             @click="confirmAllClockInPairs"
             :disabled="allClockInsAreConfirmed || source.disabled"
             x-small fab width="25px" height="25px" depressed
             style="position: absolute; left: 180px; top: 15px">
        <v-icon size="13px">{{ icons.mdiCheck }}</v-icon>
      </v-btn>

      <div style="position: absolute; right: 3px; top: 50%; transform: translateY(-50%);" class="px-3">
        <v-btn v-if="!source.disabled"
               @click="openNewClockInHandler"
               x-small fab width="25px" height="25px" depressed>
          <v-icon size="13px">{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </div>

      <!-- Main column value -->
      <section class="wb-w100 f-12 pa-1 d-flex justify-space-between">
        <b>{{ mainColumn }}</b>

        <!-- Break duration alert -->
        <div v-if="showBreakDuration()" class="mr-1">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="yellow" class="ml-2">mdi-alert-rhombus</v-icon>
            </template>
            {{ translations.maxBreakTime }}<br />
            {{ `${translations.maxBreakTimeInterval} ${this.maxBreakTime} ${translations.minutes}` }}
          </v-tooltip>
        </div>
      </section>

      <section>
        <!-- no clockin with default project -->
        <section v-if="dataSource.clockIns.length <= 0">
          <div class="report-row">
            <p v-if="defaultProject" class="ma-0 pa-0 d-flex align-center wb-w100 f-12">
              {{ defaultProject }}
            </p>
          </div>
        </section>

        <template v-else>
          <section v-for="(clockIn, i) in computedClockIns" :key="`type_${clockIn.clockIn.id}_${clockIn.clockOut.id}`"
                   @click="startEditingClockIn($event, clockIn)"
                   class="mb-2 clockin-row"
                   :style="[
                     !getProjectKeyCodeFromClockIn(clockIn.clockIn) && clockInGeoRefIsEnabled ? { 'background-color': '#ffcccc' } : {},
                     { gridTemplateColumns: `50px ${middleColumnWidth} 120px` }
                   ]">
            <!-- Confirmed -->
            <div class="justify-center d-flex" style="margin-top:-6px">
              <v-checkbox v-if="clockIn.id > 0"
                          v-model="clockIn.confirmed"
                          @change="value => confirmClockInPair(clockIn, value)"
                          :disabled="source.disabled"
                          color="main" hide-details dense>
              </v-checkbox>
              <v-icon v-else-if="clockIn.confirmed" dense>{{ icons.mdiCheck }}</v-icon>
            </div>

            <!-- Project / Category -->
            <div class="clockin-mid-grid-row" :style="{ maxWidth: middleColumnWidth, gridTemplateColumns: middleContainerTemplateColumns }">
              <div v-if="order === 'employee'" class="report-row">
                <p v-if="!clockIn.edit" :key="forceUpdateLabel" class="ma-0 pa-0 d-flex align-center f-12 wb-w100">
                  {{ getProjectKeyCodeFromClockIn(clockIn.clockIn) }}
                </p>

                <v-menu v-else-if="!clockIn.maintenance && !clockIn.transfer"
                        :key="forceUpdate"
                        v-model="clockIn.openMenu"
                        :value="clockIn.openMenu"
                        :disabled="clockIn.confirmed || source.disabled"
                        :open-on-click="false"
                        :open-on-focus="true"
                        :close-on-click="false"
                        :close-on-content-click="false"
                        offset-x allow-overflow max-height="400px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-on="on"
                                  v-model="clockIn.search"
                                  @click.stop="clockIn.openMenu = true"
                                  :disabled="clockIn.confirmed || source.disabled"
                                  @blur="textBlur(clockIn)"
                                  @input="value => inputHandler(value)"
                                  autocomplete="off" height="13px" hide-details outlined
                                  class="f-12" style="width: 90px;">
                    </v-text-field>
                  </template>

                  <div style="max-height: 300px; z-index: 2; background-color: #fff" class="scroll-bar">
                    <div class="d-flex align-center justify-space-between px-2 pt-2">
                      <div class="d-flex align-center mr-2">
                        <v-btn @click.prevent="$emit('set-active-menu-list', 'projects'), (changedProjectList = true)"
                               x-small class="mr-2" :class="[activeMenuList === 'projects' ? 'white--text' : '']"
                               :outlined="activeMenuList !== 'projects'" color="main">
                          {{ translations.projects }}
                        </v-btn>
                        <v-btn @click.prevent="$emit('set-active-menu-list', 'categories'), (changedProjectList = true)"
                               x-small :class="[activeMenuList === 'categories' ? 'white--text' : '']"
                               :outlined="activeMenuList !== 'categories'" color="main">
                          {{ translations.categories }}
                        </v-btn>
                      </div>

                      <v-btn x-small icon @click.stop="closeMenuHandler(clockIn)">
                        <v-icon x-small>{{ icons.mdiClose }}</v-icon>
                      </v-btn>
                    </div>

                    <v-list dense>
                      <template v-if="dataSource.projects.length > 0">
                        <v-subheader>{{ translations.suggestedProjects }}</v-subheader>
                        <v-list-item v-for="project in dataSource.projects.map(a => a.project)"
                                     :key="`s_p_clockIn_${clockIn.clockIn.id}${project.id}`"
                                     @click="changeProject(clockIn, project)">
                          <v-list-item-content>
                            <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                            <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-if="activeMenuList === 'projects'">
                        <v-subheader>{{ translations.projects }}</v-subheader>
                        <v-list-item v-for="(project, i) in rowProjectsOption.projects"
                                     @click="changeProject(clockIn, project)"
                                     :key="`other_clockIn${clockIn.clockIn.id}__${project.id}_${i}`">
                          <v-list-item-content>
                            <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                            <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="!rowProjectsOption.loading && rowProjectsOption.hasNext">
                          <div class="d-flex align-center justify-center"
                               style="width: 100%">
                            <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                          </div>
                        </v-list-item>

                        <v-list-item v-if="!rowProjectsOption.loading && !rowProjectsOption.hasNext && rowProjectsOption.projects.length === 0">
                          <v-list-item-content>
                            <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-else-if="activeMenuList === 'categories'">
                        <v-subheader>{{ translations.categories }}</v-subheader>
                        <v-list-item v-for="categoryProject in rowCategoryOptions.projects"
                                     :key="categoryProject.keyCode"
                                     @click="changeProject(clockIn, categoryProject)">
                          <v-list-item-content>
                            <v-list-item-title>{{ categoryProject.keyCode }}</v-list-item-title>
                            <v-list-item-subtitle>{{ categoryProject.label }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="!rowCategoryOptions.loading && rowCategoryOptions.hasNext">
                          <div class="d-flex align-center justify-center"
                               style="width: 100%">
                            <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                          </div>
                        </v-list-item>

                        <v-list-item v-if="!rowCategoryOptions.loading && !rowCategoryOptions.hasNext && rowCategoryOptions.projects.length === 0">
                          <v-list-item-content>
                            <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </div>
                </v-menu>
              </div>

              <!-- Part -->
              <div class="report-row">
                <p v-if="!clockIn.edit && !clockIn.maintenance && !clockIn.transfer"
                   class="ma-0 pa-0"
                   style="font-size: 12px; word-wrap: break-word">
                  {{ projectPartValue(clockIn.clockIn) }}
                </p>

                <v-select v-else-if="projectParts(clockIn.clockIn) && !clockIn.maintenance && !clockIn.transfer"
                          v-model="clockIn.clockIn.part"
                          :disabled="clockIn.confirmed || source.disabled"
                          :items="projectParts(clockIn.clockIn)"
                          :no-data-text="translations.noPartsAvailable"
                          append-icon=""
                          class="part-select"
                          clearable
                          dense
                          height="15px"
                          hide-details
                          item-text="keyCode"
                          item-value="id"
                          outlined
                          return-object
                          style="font-size: 12px; width: 90px"
                          @click:clear="clockIn.clockIn.part = null">
                  <template v-slot:item="data">
                    <small>{{ `${data.item.keyCode} - ${data.item.description}` }}</small>
                  </template>
                </v-select>
              </div>

              <!-- Employee -->
              <div v-if="order === 'projectCategory' && clockIn.employee"
                   class="report-row"
                   style="font-size: 11px">
                <p class="pa-0 ma-0">{{ formatEmployeeName(clockIn.employee.name) }}</p>
              </div>

              <!-- Subpart -->
              <div class="report-row">
                <p v-if="!clockIn.edit && !clockIn.maintenance && !clockIn.transfer"
                   class="pa-0 ma-0"
                   style="font-size: 12px; word-wrap: break-word">
                  {{ clockIn.subpart }}
                </p>
                <v-text-field v-else-if="!clockIn.maintenance && !clockIn.transfer"
                              v-model="clockIn.subpart"
                              :disabled="clockIn.confirmed || source.disabled"
                              height="13px"
                              hide-details
                              outlined
                              style="width: 60px; font-size:12px"></v-text-field>
              </div>

              <!-- Allowance -->
              <div class="report-row">
                <p v-if="!clockIn.edit && !clockIn.maintenance && !clockIn.transfer"
                   class="pa-0 ma-0"
                   style="font-size: 12px; word-wrap: break-word;">
                  {{ clockIn.clockIn.allowance }}
                </p>
                <v-text-field v-else-if="!clockIn.maintenance && !clockIn.transfer"
                              v-model="clockIn.clockIn.allowance"
                              :disabled="clockIn.confirmed || (resetAllowanceOnWeekend && dateIsOnWeekend) || source.disabled"
                              height="13px"
                              hide-details
                              outlined
                              style="width: 40px; font-size:12px"></v-text-field>
              </div>

              <!-- Clock IN -->
              <div class="report-row d-flex align-center" style="height: 32px">
                <!-- time alert icon -->
                <div class="d-flex justify-center align-center" style="width: 20px">
                  <v-btn v-if="showClockInTimeAlert && clockIn.clockIn.updatedTimestampStr !== clockIn.clockIn.originalTimestampStr" x-small icon color="orange darken-2" style="cursor: default">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-alert-rhombus</v-icon>
                      </template>
                      <span>{{ translations.originalTimestamp }}: {{ formatHHmm(clockIn.clockIn.originalTimestampStr) }}</span>
                    </v-tooltip>
                  </v-btn>
                </div>

                <div style="width: 60px">
                  <template v-if="!clockIn.edit">
                    <v-chip v-if="clockIn.clockIn.updatedTimestampStr" label small :color="`${chipColor(clockIn.clockIn.type)} lighten-5`">
                    <span :class="`${chipColor(clockIn.clockIn.type)}--text`" class="mr-1">
                      {{ formatHHmm(clockIn.clockIn.updatedTimestampStr) }}
                    </span>
                    </v-chip>
                  </template>

                  <v-text-field v-else v-model="clockIn.clockIn.updatedTimestamp"
                                v-mask="'##:##'" :rules="[rules.isTimeFormat]"
                                :disabled="clockIn.confirmed || source.disabled"
                                height="13px" style="width: 60px; max-width: 60px; font-size:12px" outlined hide-details>
                  </v-text-field>
                </div>

                <!-- Location (Clock In) -->
                <div class="d-flex justify-center align-center" style="width: 20px">
                  <v-btn v-if="clockIn.clockIn.geolocation" x-small icon color="main" class="mapLink" @click="displayMap(clockIn.clockIn.project, clockIn.clockIn.geolocation, clockIn.clockOut ? clockIn.clockOut.geolocation : null, 'IN')">
                    <v-icon class="mapLink" style="filter: hue-rotate(240deg);">mdi-map-marker</v-icon>
                  </v-btn>
                </div>
              </div>

              <!-- Clock Out -->
              <div class="report-row d-flex">
                <!-- time alert icon -->
                <div class="d-flex justify-center align-center" style="width: 20px">
                  <v-btn v-if="showClockInTimeAlert && clockIn.clockOut.updatedTimestampStr !== clockIn.clockOut.originalTimestampStr" x-small icon color="orange darken-2" style="cursor: default">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-alert-rhombus</v-icon>
                      </template>
                      <span>{{ translations.originalTimestamp }}: {{ formatHHmm(clockIn.clockOut.originalTimestampStr) }}</span>
                    </v-tooltip>
                  </v-btn>
                </div>

                <div style="width: 60px">
                  <template v-if="!clockIn.edit">
                    <v-chip v-if="clockIn.clockOut.type && clockIn.clockOut.updatedTimestamp && clockIn.clockOut.updatedTimestampStr" label small :color="`${chipColor(clockIn.clockOut.type)} lighten-5`">
                    <span :class="`${chipColor(clockIn.clockOut.type)}--text`" class="mr-1">
                      {{ formatHHmm(clockIn.clockOut.updatedTimestampStr) }}
                    </span>
                    </v-chip>
                  </template>

                  <template v-if="clockIn.edit">
                    <v-text-field v-model="clockIn.clockOut.updatedTimestamp"
                                  v-mask="'##:##'" :rules="[rules.isTimeFormat]"
                                  :disabled="clockIn.confirmed || source.disabled"
                                  height="13px" style="width: 60px; max-width: 60px; font-size:12px" outlined hide-details>
                    </v-text-field>
                  </template>
                </div>

                <!-- Location (Clock Out) -->
                <div class="d-flex justify-center align-center" style="width: 20px">
                  <v-btn v-if="clockIn.clockOut.geolocation" x-small icon color="main" class="mapLink" @click="displayMap(clockIn.clockIn.project, clockIn.clockIn.geolocation, clockIn.clockOut.geolocation, 'OUT')">
                    <v-icon class="mapLink" style="filter: hue-rotate(140deg);">mdi-map-marker</v-icon>
                  </v-btn>
                </div>

                <!-- Break Time -->
                <div class="d-flex justify-center align-center" style="width: 20px">
                  <v-tooltip top v-if="showBreakDuration() && clockIn.clockIn.id && i > 0 && !clockIn.confirmed && !source.disabled">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" x-small icon color="#0661FF" @click="adjustBreakTime(clockIn)">
                        <v-icon>mdi-timer-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    {{ translations.adjustBreakTime }}
                  </v-tooltip>
                </div>

                <div class="d-flex justify-center align-center" style="width: 20px">
                  <template v-if="clockIn.edit">
                    <v-btn v-if="clockIn.clockIn.id && clockIn.clockOut.id" x-small :disabled="clockIn.confirmed || source.disabled" icon color="red" @click="deleteClockOutFromClockInPair(clockIn)">
                      <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                    </v-btn>
                  </template>
                </div>
              </div>

              <!-- Minutes Worked -->
              <div class="justify-center d-flex report-row">
                <span class="pa-0 ma-0"
                      style="font-size: 12px;">
                  {{ clockIn.clockOut.minutesWorked }}
                </span>
              </div>

              <!-- Work Hours -->
              <div class="justify-center d-flex report-row">
                <template v-if="order !== 'projectCategory' && i === 0">
                  <span class="pa-0 ma-0"
                        style="font-size: 12px;">
                    {{ sourceCopy.workHours }}
                  </span>
                </template>

                <template v-else-if="order === 'projectCategory'">
                  <span class="pa-0 ma-0"
                        style="font-size: 12px;">
                    {{ clockIn.workHours }}
                  </span>
                </template>
              </div>

              <!-- Lunch Break -->
              <div v-if="possibleQuestions.includes(4)"
                   class="justify-center d-flex report-row">
                <template v-if="!clockIn.edit">
                  <span class="pa-0 ma-0"
                        style="font-size: 12px;">
                    {{ clockIn.lunchTime || clockIn.lunchTime === 0 ? getLunchText(clockIn.lunchTime) : '' }}
                  </span>
                </template>

                <template v-else>
                  <v-select v-if="(clockIn.lunchTime !== null && clockIn.lunchTime >= 0) || !hasLunchTime"
                            v-model="clockIn.lunchTime"
                            :disabled="clockIn.confirmed || source.disabled"
                            :items="lunchBreakOptions"
                            append-icon=""
                            dense
                            height="15px"
                            hide-details
                            outlined
                            style="font-size: 12px; width: 80px; max-width: 90px">
                  </v-select>
                </template>
              </div>

              <!-- Layoff -->
              <div class="layoff d-flex justify-center">
                <v-switch v-if="clockIn.edit"
                          v-model="clockIn.layoff"
                          :disabled="clockIn.confirmed || source.disabled"
                          dense
                          hide-details></v-switch>

                <v-chip v-else
                        :color="getColor(clockIn.layoff).bg"
                        label
                        small>
                  <span :style="[{ color: getColor(clockIn.layoff).text }]">{{
                      clockIn.layoff ? translations.yes : translations.no }}</span>
                </v-chip>
              </div>

              <!-- Van -->
              <div v-if="possibleQuestions.includes(1)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openVanDialog(clockIn)">
                  {{ getVanText(clockIn) }}
                </v-btn>
              </div>

              <!-- Refuel -->
              <div v-if="possibleQuestions.includes(2)"
                   class="justify-center d-flex">
                <v-btn :color="areClockInItemsRefuelIncompleted(clockIn) ? 'red' : 'black'"
                       :disabled="clockIn.confirmed || !clockIn.clockOut.updatedTimestamp || source.disabled"
                       outlined
                       x-small
                       @click="openRefuelDialog(clockIn)">
                  {{ hasItems(clockIn.clockOut) ? translations.yes : translations.no }}
                </v-btn>
              </div>

              <!-- Maintenance -->
              <div class="justify-center d-flex">
                <v-btn :color="areClockInItemsMaintenanceIncompleted(clockIn) ? 'red' : 'black'"
                       :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="openMaintenanceDialog(clockIn)">
                  {{ clockIn.maintenance ? translations.yes : translations.no }}
                </v-btn>
              </div>

              <!-- Milling -->
              <div v-if="possibleQuestions.includes(3)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openSwitchHourDialog(clockIn, 'milling', 'millingHours')">
                  {{ getHoursText(clockIn, 'milling', 'millingHours') }}
                </v-btn>
              </div>

              <!-- Preparation -->
              <div v-if="possibleQuestions.includes(20)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openSwitchHourDialog(clockIn, 'preparation', 'preparationHours', 'preparationLong')">
                  {{ getHoursText(clockIn, 'preparation', 'preparationHours') }}
                </v-btn>
              </div>

              <!-- Boots -->
              <div v-if="possibleQuestions.includes(15)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openSwitchHourDialog(clockIn, 'boots', 'bootsHours')">
                  {{ getHoursText(clockIn, 'boots', 'bootsHours') }}
                </v-btn>
              </div>

              <!-- Demolition Man -->
              <div v-if="possibleQuestions.includes(16)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openSwitchHourDialog(clockIn, 'demolitionMan', 'demolitionManHours')">
                  {{ getHoursText(clockIn, 'demolitionMan', 'demolitionManHours') }}
                </v-btn>
              </div>

              <!-- Confined Spaces -->
              <div v-if="possibleQuestions.includes(17)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openSwitchHourDialog(clockIn, 'confinedSpaces', 'confinedSpacesHours')">
                  {{ getHoursText(clockIn, 'confinedSpaces', 'confinedSpacesHours') }}
                </v-btn>
              </div>

              <!-- Deep Excavations -->
              <div v-if="possibleQuestions.includes(18)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openSwitchHourDialog(clockIn, 'deepExcavations', 'deepExcavationsHours')">
                  {{ getHoursText(clockIn, 'deepExcavations', 'deepExcavationsHours') }}
                </v-btn>
              </div>

              <!-- Driving -->
              <div v-if="possibleQuestions.includes(19)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openSwitchHourDialog(clockIn, 'driving', 'drivingHours')">
                  {{ getHoursText(clockIn, 'driving', 'drivingHours') }}
                </v-btn>
              </div>

              <!-- Dpi Verification -->
              <div v-if="possibleQuestions.includes(5)"
                   class="layoff d-flex justify-center">
                <v-switch v-if="clockIn.edit"
                          v-model="clockIn.dpiVerification"
                          :disabled="clockIn.confirmed || source.disabled"
                          dense
                          hide-details></v-switch>

                <v-chip v-else
                        :color="getColor(clockIn.dpiVerification).bg"
                        label
                        small>
                  <span :style="[{ color: getColor(clockIn.dpiVerification).text }]">{{
                      clockIn.dpiVerification ? translations.yes : translations.no }}</span>
                </v-chip>
              </div>

              <!-- Material Used -->
              <div v-if="possibleQuestions.includes(6)"
                   class="justify-center d-flex report-row">
                <v-select v-if="clockIn.edit"
                          v-model="clockIn.materialUsed"
                          :disabled="clockIn.confirmed || source.disabled"
                          :items="materials"
                          :placeholder="translations.selectOption"
                          append-icon=""
                          dense
                          height="13px"
                          hide-details
                          item-text="text"
                          item-value="value"
                          outlined
                          style="font-size: 12px; width: 80px; max-width: 90px">
                </v-select>
                <span v-else
                      class="pa-0 ma-0"
                      style="font-size: 12px;">{{ clockIn.materialUsed ? clockIn.materialUsed : '' }}</span>
              </div>

              <!-- Asphalt Temperature -->
              <div v-if="possibleQuestions.includes(7)"
                   class="justify-center d-flex report-row">
                <v-text-field v-if="clockIn.edit"
                              v-model="clockIn.asphaltTemperature"
                              :disabled="clockIn.confirmed || source.disabled"
                              class="text-field"
                              dense
                              height="13px"
                              hide-details
                              outlined
                              style="font-size: 12px; width: 50px; max-width: 60px"></v-text-field>
                <span v-else
                      class="pa-0 ma-0"
                      style="font-size: 12px;">{{ clockIn.asphaltTemperature ? clockIn.asphaltTemperature : '-' }}</span>
              </div>

              <!-- Environment Temperature -->
              <div v-if="possibleQuestions.includes(8)"
                   class="justify-center d-flex report-row">
                <v-text-field v-if="clockIn.edit"
                              v-model="clockIn.environmentTemperature"
                              :disabled="clockIn.confirmed || source.disabled"
                              class="text-field"
                              dense
                              height="13px"
                              hide-details
                              outlined
                              style="font-size: 12px; width: 50px; max-width: 60px"></v-text-field>
                <span v-else
                      class="pa-0 ma-0"
                      style="font-size: 12px;">{{
                    clockIn.environmentTemperature ? clockIn.environmentTemperature : '-' }}</span>
              </div>

              <!-- Man Holes Verification -->
              <div v-if="possibleQuestions.includes(9)"
                   class="justify-center d-flex layoff">
                <v-switch v-if="clockIn.edit"
                          v-model="clockIn.manHolesVerification"
                          :disabled="clockIn.confirmed || source.disabled"
                          dense
                          hide-details></v-switch>

                <v-chip v-else
                        :color="getColor(clockIn.manHolesVerification).bg"
                        label
                        small>
                  <span :style="[{ color: getColor(clockIn.manHolesVerification).text }]">{{
                      clockIn.manHolesVerification ? translations.yes : translations.no }}</span>
                </v-chip>
              </div>

              <!-- Transfer -->
              <div v-if="clockInTransferIsEnabled"
                   class="layoff d-flex justify-center">
                <v-switch v-if="clockIn.edit"
                          v-model="clockIn.transfer"
                          :disabled="clockIn.confirmed || source.disabled"
                          dense
                          hide-details></v-switch>

                <v-chip v-else
                        :color="getColor(clockIn.transfer).bg"
                        label
                        small>
                  <span :style="[{ color: getColor(clockIn.transfer).text }]">{{
                      clockIn.transfer ? translations.yes : translations.no }}</span>
                </v-chip>
              </div>

              <!-- Vehicle Code -->
              <div class="report-row">
                <p v-if="!clockIn.edit"
                   class="pa-0 ma-0"
                   style="font-size: 12px; word-wrap: break-word; text-align: center">
                  {{ clockIn.vehicleCode }}
                </p>
                <v-text-field v-else
                              v-model.trim="clockIn.vehicleCode"
                              :disabled="clockIn.confirmed || source.disabled"
                              height="13px"
                              hide-details
                              outlined
                              style="width: 70px; font-size:12px"></v-text-field>
              </div>

              <!-- Security Report -->
              <div v-if="possibleQuestions.includes(11)"
                   class="justify-center d-flex">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openSecurityDialog(clockIn)">
                  {{ getSecurityTypeText(clockIn) }}
                </v-btn>
              </div>

              <!-- Equipment -->
              <div v-if="possibleQuestions.includes(13) || possibleQuestions.includes(21)"
                   class="justify-center d-flex">
                <v-btn :color="areClockInVehiclesIncompleted(clockIn) ? 'red' : 'black'"
                       :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openEquipmentDialog(clockIn)">
                  {{ getEquipmentTypeText(clockIn) }}
                </v-btn>
              </div>

              <!-- Job description -->
              <div class="report-row">
                <v-tooltip v-if="!clockIn.edit" :disabled="!clockIn.jobDescription" bottom color="black" max-width="300px">
                  <template v-slot:activator="{ on, attrs }">
                    <p v-on="on" v-bind="attrs" class="pa-0 ma-0" style="font-size: 10px; line-height: 16px; text-align: center; max-height: 30px;">
                      {{ clockIn.jobDescription }}
                    </p>
                  </template>
                  <span>{{ clockIn.jobDescription }}</span>
                </v-tooltip>

                <v-textarea v-else v-model.trim="clockIn.jobDescription" rows="1" class="ma-0 pa-0 clock-in-notes" outlined hide-details :disabled="clockIn.confirmed || source.disabled"></v-textarea>
              </div>

              <!-- Notes -->
              <div class="report-row">
                <v-tooltip v-if="!clockIn.edit"
                           :disabled="!clockIn.notes"
                           bottom
                           color="black"
                           max-width="300px">
                  <template v-slot:activator="{ on, attrs }">
                    <p class="pa-0 ma-0"
                       style="font-size: 10px; line-height: 16px; text-align: center; max-height: 30px;"
                       v-bind="attrs"
                       v-on="on">
                      {{ clockIn.notes }}
                    </p>
                  </template>
                  <span>{{ clockIn.notes }}</span>
                </v-tooltip>

                <v-textarea v-else
                            v-model.trim="clockIn.notes"
                            :disabled="clockIn.confirmed || source.disabled"
                            class="ma-0 pa-0 clock-in-notes"
                            hide-details
                            outlined
                            rows="1"></v-textarea>
              </div>

              <!-- Reference date -->
              <div class="report-row">
                <v-select v-if="clockIn.edit && clockIn.id > 0"
                          v-model="clockIn.referenceDate"
                          :disabled="clockIn.confirmed || source.disabled"
                          :items="referenceDateOptions(source.date)"
                          append-icon=""
                          dense
                          height="25px"
                          hide-details
                          outlined
                          style="font-size: 12px; width: 90px; padding-top: 2px;">
                </v-select>
              </div>
            </div>

            <!-- Actions -->
            <div :class="[clockIn.edit ? 'justify-start' : 'justify-center']"
                 class="d-flex pl-2">
              <div v-if="!clockIn.loading && !source.disabled">
                <div v-if="clockIn.edit">
                  <v-btn :disabled="isSaveRowButtonDisabled(clockIn)"
                         class="mr-2"
                         icon
                         x-small
                         @click="saveRow(clockIn)">
                    <v-icon>{{ icons.mdiContentSaveOutline }}</v-icon>
                  </v-btn>

                  <v-btn class="mr-2"
                         icon
                         x-small
                         @click.stop="onCancel(clockIn)">
                    <v-icon>{{ icons.mdiCancel }}</v-icon>
                  </v-btn>

                  <v-btn v-if="clockIn.id > 0"
                         color="red"
                         icon
                         x-small
                         @click="deleteClockInPair(clockIn)">
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </div>

                <v-btn v-else-if="!clockIn.confirmed"
                       icon
                       x-small
                       @click="startEditingClockIn($event, clockIn)">
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                </v-btn>
              </div>
              <v-btn v-else-if="clockIn.loading"
                     :loading="true"
                     icon></v-btn>
            </div>
          </section>
        </template>
      </section>
    </main>
    <v-divider></v-divider>

    <!-- Map Dialog -->
    <clock-in-map :clockInMap="clockInMap"
                  @close="clockInMap.showDialog = false" />

    <!-- New ClockIn Dialog -->
    <new-clock-in-clock-out-dialog v-if="clockInDialog"
                                   :date="source.date"
                                   :disabled="source.disabled"
                                   :employeeName="sourceCopy.name"
                                   :isOpen="clockInDialog"
                                   :lunchBreakOptions="lunchBreakOptions"
                                   :newClockInLoader="newClockInLoader"
                                   :orderBy="orderBy"
                                   :project="sourceCopy.project"
                                   :referenceDateOptions="referenceDateOptions(source.date)"
                                   :rowDialogCategoryOptions="rowDialogCategoryOptions"
                                   :rowDialogEmployeeOptions="rowDialogEmployeeOptions"
                                   :rowDialogProjectOptions="rowDialogProjectOptions"
                                   @clickOutside="closeHandler"
                                   @onSubmit="saveNewClockInClockOut"
                                   @fetch-more-projects="$emit('fetch-more-projects', 'rowDialogProjectOptions')"
                                   @fetch-more-categories="$emit('fetch-more-categories', 'rowDialogCategoryOptions')"
                                   @fetch-more-employees="$emit('fetch-more-employees', 'rowDialogEmployeeOptions')"
                                   @set-active-menu-list="value => $emit('set-active-menu-list', value)"
                                   @set-project-list-filter="value => $emit('set-project-list-filter', value)"
                                   @set-project-category-list-filter="value => $emit('set-project-category-list-filter', value)"
                                   @set-employee-list-filter="value => $emit('set-employee-list-filter', value)">
    </new-clock-in-clock-out-dialog>

    <!-- Van Dialog -->
    <v-dialog v-if="vanDialog"
              v-model="vanDialog"
              :persistent="selectedClockInPair.van && !selectedClockInPair.vanCode"
              max-width="650px">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ translations.van }}</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  {{ translations.van }}
                </th>
                <th v-if="selectedClockInPair.van"
                    class="text-left">
                  {{ translations.vanCode }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="report-row">
                  <v-switch v-model="selectedClockInPair.van"
                            dense
                            hide-details></v-switch>
                </td>
                <td class="report-row">
                  <v-text-field v-if="selectedClockInPair.van"
                                v-model.trim="selectedClockInPair.vanCode"
                                height="13px"
                                hide-details
                                outlined
                                style="width: 100px; font-size:12px"></v-text-field>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Refuel Dialog -->
    <v-dialog v-if="refuelDialog"
              v-model="refuelDialog"
              max-width="650px">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ translations.refuel }}</span>
          <v-btn color="main"
                 dark
                 depressed
                 fab
                 x-small
                 @click="addRefuelVehicle()">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  {{ translations.vehicleCode }}
                </th>
                <th class="text-left">
                  {{ translations.gasLiters }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in selectedClockInPair.clockOut.clockInItems"
                  :key="index">
                <td class="report-row">
                  <v-text-field v-model.trim="item.vehicleCode"
                                :rules="[rules.isNotBlank]"
                                height="13px"
                                hide-details
                                outlined
                                style="width: 100px; font-size:12px"></v-text-field>
                </td>
                <td class="report-row">
                  <v-text-field v-model="item.quantity"
                                :rules="[rules.isGreaterThanZero]"
                                height="13px"
                                hide-details
                                min="0"
                                outlined
                                step="0.01"
                                style="width: 100px; font-size:12px"
                                type="number"
                                @input="onClockInItemQuantityChange(item, item.quantity)">
                  </v-text-field>
                </td>
                <td>
                  <div>
                    <v-btn color="red"
                           icon
                           x-small
                           @click="deleteRefuelVehicle(index)">
                      <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Maintenance Dialog -->
    <v-dialog v-if="maintenanceDialog"
              v-model="maintenanceDialog"
              max-width="400px">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ translations.maintenance }}</span>
          <v-btn color="main"
                 dark
                 depressed
                 fab
                 x-small
                 @click="addMaintenanceVehicle()">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  {{ translations.vehicleCode }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in selectedClockInPair.clockIn.clockInItems"
                  :key="index">
                <td class="report-row">
                  <v-text-field v-model.trim="item.vehicleCode"
                                :rules="[rules.isNotBlank]"
                                height="13px"
                                hide-details
                                outlined
                                style="width: 100px; font-size:12px"></v-text-field>
                </td>
                <td>
                  <div>
                    <v-btn color="red"
                           icon
                           x-small
                           @click="deleteMaintenanceVehicle(index)">
                      <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Milling, Preparation, Boots DemolitionMan, Confined Spaces, Deep Excavations, Driving Dialog -->
    <v-dialog v-if="switchHourDialog"
              v-model="switchHourDialog"
              :persistent="missingTime"
              max-width="650px"
              @click:outside="closeSwitchHourDialogHandler">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ translations[dialogTitle] }}</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  {{ translations[dialogType] }}
                </th>
                <th class="text-left" v-if="selectedClockInPair[dialogType]">
                  {{ translations.hours }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="report-row">
                  <v-switch v-model="selectedClockInPair[dialogType]"
                            dense
                            hide-details></v-switch>
                </td>
                <td class="report-row">
                  <v-text-field v-if="selectedClockInPair[dialogType]"
                                v-model="selectedClockInPair[dialogTypeHours]"
                                :rules="[rules.isHour]"
                                height="13px"
                                hide-details
                                min="0"
                                outlined
                                step="0.5"
                                style="width: 100px; font-size:12px"
                                type="number">
                  </v-text-field>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Security Dialog -->
    <v-dialog v-model="securityDialog" max-width="650px" :persistent="selectedClockInPair.didSecurityReport && (selectedClockInPair.securityType.length === 0 || selectedClockInPair.securityNote === '')">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ translations.security }}</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th scope="col">
                  {{ translations.enabled }}
                </th>
                <th scope="col">
                  <v-switch v-model="selectedClockInPair.didSecurityReport"
                            dense
                            hide-details></v-switch>
                </th>
              </tr>
              </thead>
              <tbody v-if="selectedClockInPair.didSecurityReport">
              <tr>
                <td>
                  {{ translations.securityType }}
                </td>
                <td>
                  <div class="security-type-container">
                    <v-checkbox v-for="(type, index) in securityTypeOptions" :key="index"
                                v-model="selectedClockInPair.securityType"
                                :label="type.label" :value="type.value"
                                class="checkbox">
                    </v-checkbox>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  {{ translations.securityNote }}
                </td>

                <td class="report-row">
                  <v-textarea v-model="selectedClockInPair.securityNote" class="textarea" variant="filled" auto-grow rows="3">
                  </v-textarea>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Equipment Dialog -->
    <v-dialog v-if="equipmentDialog"
              v-model="equipmentDialog"
              max-width="650px">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ translations.equipments }}</span>
          <v-btn @click="addEquipment()" color="main" dark depressed fab x-small>
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="mb-3">
            <v-col cols="8">{{ translations.equipmentCode }}</v-col>
            <v-col cols="3">{{ translations.hours }}</v-col>
            <v-col cols="1"></v-col>
          </v-row>

          <v-col v-for="(item, index) in selectedClockInPair.clockOut.clockInVehicles" :key="index" class="pa-0">
            <v-divider class="my-2"></v-divider>

            <v-row no-gutters>
              <v-col cols="8" class="report-row">
                <smart-autocomplete-vehicles :key="`vehicle-smart-autocomplete-${index}`"
                                             :applyDateFilter="true"
                                             :dateStr="selectedDate"
                                             :keyIndex="index"
                                             :vehicle="item.vehicle"
                                             @set-value="vehicle => setClockInVehicle(vehicle, index)">
                </smart-autocomplete-vehicles>
              </v-col>
              <v-col cols="3" class="report-row">
                <v-text-field v-model="item.hours"
                              :rules="[rules.isGreaterThanZero]"
                              height="13px"
                              hide-details
                              min="0"
                              outlined
                              step="0.5"
                              style="width: 100px; font-size:12px"
                              type="number"></v-text-field>
              </v-col>
              <v-col cols="1">
                <div>
                  <v-btn color="red"
                         icon
                         x-small
                         @click="deleteEquipment(index)">
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import {
  mdiCancel,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiContentSaveOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiWeatherNight
} from '@mdi/js'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import { translations } from '@/utils/common'
import updateOneClockInMutation from '@/graphql/UpdateOneClockIn.gql'
import saveClockInAdminRequestMutation from '@/graphql/SaveClockInAdminRequest.gql'
import deleteClockInAndOutMutation from '@/graphql/DeleteClockInAndOut.gql'
import workHoursQuery from '@/graphql/WorkHours.gql'
import deleteClockInMutation from '@/graphql/DeleteClockIn.gql'
import updateMaxBreakTime from '@/graphql/UpdateMaxBreakTime.gql'
import { mapState } from 'vuex'
import NewClockInClockOutDialog from './NewClockInClockOutDialog.vue'
import {
  getClockInGeoRefIsEnabled,
  getClockInTransferIsEnabled,
  getLunchBreak,
  getLunchTimes,
  getResetAllowanceOnWeekend,
  getSecurityTypes,
  getShowClockInTimeAlert
} from '@/utils/api/config'
import ClockInMap from '@/components/ClockInMap.vue'
import EmployeeService from '@/service/employee/EmployeeService'

const SmartAutocompleteVehicles = () => import ('@/components/SmartAutocompleteVehicles')

export default {
  name: 'VirtualReportRow',

  components: {
    ClockInMap,
    NewClockInClockOutDialog,
    SmartAutocompleteVehicles
  },

  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default: () => ({})
    },
    possibleQuestions: {
      type: Array,
      default: () => []
    },
    activeMenuList: {
      type: String,
      default: 'projects'
    },
    middleColumnWidth: {
      type: String,
      required: true
    },
    middleContainerTemplateColumns: {
      type: String,
      required: true
    },
    materials: {
      type: Array,
      default: () => []
    },
    rowDialogProjectOptions: {
      type: Object,
      default: () => {
      }
    },
    rowProjectsOption: {
      type: Object,
      default: () => {
      }
    },
    rowDialogCategoryOptions: {
      type: Object,
      default: () => {
      }
    },
    rowCategoryOptions: {
      type: Object,
      default: () => {
      }
    },
    rowDialogEmployeeOptions: {
      type: Object,
      default: () => {
      }
    },
    orderBy: {
      type: String,
      default: () => {
      }
    },
    selectedDate: {
      type: String,
      default: ''
    },
    confirmTypeBasedOnCapabilities: {
      type: Number,
      default: 1
    },
    maxMinutesBreakTime: {
      type: Number
    }
  },

  created() {
    // A copy of the original data which is modified
    this.dataSource = cloneDeep(this.source)
    // The original data, used as source to restore dataSource to an unmodified state
    this.sourceCopy = cloneDeep(this.source)
    this.order = this.orderBy
    this.lunchBreak = getLunchBreak()
    this.maxBreakTime = this.maxMinutesBreakTime
  },

  mixins: [MomentMixin],

  data: () => ({
    clockInMap: {
      showDialog: false,
      projectName: null,
      projectLocation: null,
      employeeName: null,
      employeeInLocation: null,
      employeeOutLocation: null,
      clickOn: null
    },
    dataSource: {},
    sourceCopy: {},
    forceUpdate: 0,
    forceUpdateLabel: 0,
    selectedClockInPair: {},
    clockInDialog: false,
    vanDialog: false,
    refuelDialog: false,
    securityDialog: false,
    newClockInLoader: false,
    maintenanceDialog: false,
    disableNewCreationBtn: false,
    rules: {
      isTimeFormat: value => {
        const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        return pattern.test(value)
      },
      isGreaterThanZero: value => {
        return value > 0
      },
      isNotBlank: value => {
        return value && value.trim() != ''
      },
      isHour: value => {
        const pattern = /^(?!0$)\d+(?:[,.][05])?$/
        return pattern.test(value)
      }
    },
    changedProjectList: false,
    equipmentDialog: false,
    order: null,
    switchHourDialog: false,
    dialogType: '',
    dialogTitle: '',
    dialogTypeHours: '',
    lunchBreak: {},
    maxBreakTime: 0
  }),

  watch: {
    source: {
      deep: true,
      handler(val) {
        this.dataSource = cloneDeep(val)
        this.sourceCopy = cloneDeep(this.source)
      }
    },

    orderBy() {
      this.order = this.orderBy
    }
  },

  methods: {
    formatHHmm(timestamp) {
      return timestamp ? this.momentDate(timestamp, 'HH:mm') : null
    },

    textBlur(clockIn) {
      setTimeout(() => {
        if (this.changedProjectList) {
          this.changedProjectList = false
          return
        } else {
          clockIn.search = this.getProjectKeyCodeFromClockIn(clockIn.clockIn)
          clockIn.openMenu = false
          this.$emit('set-project-list-filter', { value: '', property: 'rowProjectsOption' })
          this.$emit('set-project-category-list-filter', { value: '', property: 'rowCategoryOptions' })
        }
      }, 400)
    },

    deleteMaintenanceVehicle(index) {
      this.selectedClockInPair.clockIn.clockInItems.splice(index, 1)
      if (this.selectedClockInPair.clockIn.clockInItems.length === 0) {
        this.selectedClockInPair.maintenance = false
      }
    },

    addMaintenanceVehicle() {
      const newVehicle = {
        quantity: 0.0,
        hour: 0,
        vehicleCode: ''
      }
      if (!this.selectedClockInPair.clockIn.clockInItems) this.selectedClockInPair.clockIn.clockInItems = []
      this.selectedClockInPair.clockIn.clockInItems.push(newVehicle)
      this.selectedClockInPair.maintenance = true
    },

    deleteRefuelVehicle(index) {
      this.selectedClockInPair.clockOut.clockInItems.splice(index, 1)
    },

    deleteEquipment(index) {
      this.selectedClockInPair.clockOut.clockInVehicles.splice(index, 1)
    },

    addRefuelVehicle() {
      const newVehicle = {
        quantity: 0.0,
        hour: 0,
        vehicleCode: ''
      }
      if (!this.selectedClockInPair.clockOut.clockInItems) this.selectedClockInPair.clockOut.clockInItems = []
      this.selectedClockInPair.clockOut.clockInItems.push(newVehicle)
    },

    addEquipment() {
      const newEquipment = {
        hours: 0,
        vehicle: {
          code: '',
          label: ''
        }
      }
      if (!this.selectedClockInPair.clockOut.clockInVehicles) this.$set(this.selectedClockInPair.clockOut, 'clockInVehicles', [])
      this.selectedClockInPair.clockOut.clockInVehicles.push(newEquipment)
    },

    openMaintenanceDialog(clockInPair) {
      clockInPair.edit = true
      this.selectedClockInPair = clockInPair
      this.maintenanceDialog = true
    },

    openVanDialog(clockInPair) {
      clockInPair.edit = true
      this.selectedClockInPair = clockInPair
      this.vanDialog = true
    },

    openSecurityDialog(clockInPair) {
      clockInPair.edit = true
      this.selectedClockInPair = clockInPair
      this.securityDialog = true
    },

    async openEquipmentDialog(clockInPair) {
      clockInPair.edit = true
      this.selectedClockInPair = clockInPair

      // if no clockInVehicles check for suggestion from schedule
      const { clockInVehicles } = clockInPair.clockOut

      if (!clockInVehicles || !clockInVehicles?.length) {
        await this.setSuggestedEquipment(clockInPair)
      }

      this.equipmentDialog = true
    },

    async setSuggestedEquipment(clockInPair) {
      const res = await EmployeeService.getEmployeeSuggestedVehicles({
        employeeId: this.source.id,
        dateStr: clockInPair.referenceDate
      })

      if (res.length > 0) {
        const vehicles = res.map(vehicle => {
          const { code, id, label } = vehicle
          return { hours: 0, vehicle: { code, id, label } }
        })

        return this.$set(clockInPair.clockOut, 'clockInVehicles', [...vehicles])
      }
    },

    getVanText(clockIn) {
      const vanCode = clockIn.vanCode || ''
      return clockIn.van ? translations.number + ' ' + vanCode : translations.no
    },

    getSecurityTypeText(clockIn) {
      return clockIn.didSecurityReport ? translations.yes : translations.no
    },

    getEquipmentTypeText(clockIn) {
      const { clockInVehicles } = clockIn.clockOut
      return !clockInVehicles || !clockInVehicles?.length ? translations.no : translations.yes
    },

    hasItems(clockIn) {
      return clockIn.clockInItems && clockIn.clockInItems.length > 0
    },

    openRefuelDialog(clockInPair) {
      clockInPair.edit = true
      this.selectedClockInPair = clockInPair
      this.refuelDialog = true
    },

    onClockInItemQuantityChange(item, quantity) {
      if (this.countDecimals(quantity) > 2) {
        item.quantity = Number(quantity.toString().match(new RegExp('^-?\\d+(?:.\\d{0,' + (2 || -1) + '})?')))
      }
    },

    getLunchText(num) {
      const foundOption = this.lunchBreakOptions.find(lunch => lunch.value === num)

      return foundOption.text || ''
    },

    getHoursText(clockIn, type, typeHours) {
      const hours = clockIn[typeHours] || '0'
      return clockIn[type] ? hours + ' ' + translations.hours : translations.no
    },

    openSwitchHourDialog(clockInPair, type, typeHour, title) {
      clockInPair.edit = true
      this.selectedClockInPair = clockInPair
      this.dialogType = type
      this.dialogTitle = title ? title : type
      this.dialogTypeHours = typeHour
      this.switchHourDialog = true
    },

    //dialog callback
    async saveNewClockInClockOut(clockInInfo) {
      this.newClockInLoader = true

      const newClockInRequest = clockInInfo.newClockInRequest
      const newClockInType = clockInInfo.newClockInType

      const clockInTimeHH = Number(newClockInRequest.clockInTimestamp[0] + newClockInRequest.clockInTimestamp[1])
      const clockInTimemm = Number(newClockInRequest.clockInTimestamp[3] + newClockInRequest.clockInTimestamp[4])
      const clockOutTimeHH = newClockInRequest.clockOutTimestamp ? Number(newClockInRequest.clockOutTimestamp[0] + newClockInRequest.clockOutTimestamp[1]) : 0
      const clockOutTimemm = newClockInRequest.clockOutTimestamp ? Number(newClockInRequest.clockOutTimestamp[3] + newClockInRequest.clockOutTimestamp[4]) : 0

      const clockInTimestamp = moment.utc(this.source.date)
      clockInTimestamp.set('hour', Number(clockInTimeHH))
      clockInTimestamp.set('minute', Number(clockInTimemm))

      //add one day if clock out is happening the next day
      let dateOut = this.source.date
      if (clockOutTimeHH < clockInTimeHH) {
        dateOut = moment(this.source.date).add(1, 'days')
        dateOut = dateOut.format('YYYY-MM-DD')
      }

      const clockOutTimestamp = moment.utc(dateOut)
      clockOutTimestamp.set('hour', Number(clockOutTimeHH))
      clockOutTimestamp.set('minute', Number(clockOutTimemm))

      let employeeId = newClockInRequest.employee ? newClockInRequest.employee.id : this.source.id

      const clockInRequest = {
        employeeId: employeeId,
        originalTimestampStr: this.momentDate(clockInTimestamp, 'YYYY-MM-DD HH:mm'),
        type: 'IN',
        clockInItems: [],
        project: null,
        part: null,
        allowance: null,
        layoff: newClockInRequest.layoff,
        milling: newClockInRequest.milling,
        millingHours: newClockInRequest.millingHours,
        subpart: newClockInRequest.subpart,
        vehicleCode: newClockInRequest.vehicleCode,
        maintenance: false,
        van: newClockInRequest.van,
        vanCode: newClockInRequest.vanCode,
        referenceDateStr: newClockInRequest.referenceDate,
        preparation: newClockInRequest.preparation,
        preparationHours: newClockInRequest.preparationHours
      }

      const clockOutRequest = {
        employeeId: employeeId,
        project: null,
        part: null,
        originalTimestampStr: this.momentDate(clockOutTimestamp, 'YYYY-MM-DD HH:mm'),
        type: 'OUT',
        clockInItems: newClockInRequest.clockInItemsRefuel.map(a => ({
          ...a,
          id: null
        })),
        allowance: null,
        layoff: newClockInRequest.layoff,
        milling: newClockInRequest.milling,
        millingHours: newClockInRequest.millingHours,
        subpart: newClockInRequest.subpart,
        vehicleCode: newClockInRequest.vehicleCode,
        lunchTime: newClockInRequest.lunchTime,
        maintenance: false,
        van: newClockInRequest.van,
        vanCode: newClockInRequest.vanCode,
        referenceDateStr: newClockInRequest.referenceDate,
        preparation: newClockInRequest.preparation,
        preparationHours: newClockInRequest.preparationHours
      }

      switch (newClockInType) {
        case 'category': // Intentional fallthrough. A category type is handled in the same way as a project
        case 'project':
          clockInRequest.project = { id: newClockInRequest.project?.id }
          clockOutRequest.project = { id: newClockInRequest.project?.id }
          clockInRequest.allowance = newClockInRequest.project?.allowance
          clockOutRequest.allowance = newClockInRequest.project?.allowance
          if (newClockInRequest.part?.id) {
            clockInRequest.part = { id: newClockInRequest.part?.id }
            clockOutRequest.part = { id: newClockInRequest.part?.id }
          }
          break
        case 'maintenance':
          clockInRequest.clockInItems = newClockInRequest.clockInItemsMaintenance.filter(ci => ci.vehicleCode)
          clockInRequest.project = null
          clockOutRequest.project = null
          clockInRequest.part = null
          clockOutRequest.part = null
          clockInRequest.maintenance = true
          clockOutRequest.maintenance = true
          break
        case 'transfer':
          clockInRequest.project = null
          clockOutRequest.project = null
          clockInRequest.part = null
          clockOutRequest.part = null
          clockInRequest.transfer = true
          clockOutRequest.transfer = true
          break
      }

      const resIn = await this.$apollo
        .mutate({
          mutation: saveClockInAdminRequestMutation,
          variables: { clockInRequest }
        })
        .catch(error => {
          this.handleError(error)
        })

      const newClockIn = {
        geolocation: '',
        employee: resIn.data.saveClockInAdminRequest.employee,
        id: resIn.data.saveClockInAdminRequest.id,
        project: newClockInRequest.project,
        part: newClockInRequest.part,
        subpart: newClockInRequest.subpart,
        originalTimestampStr: this.momentDate(clockInTimestamp, 'YYYY-MM-DD HH:mm'),
        updatedTimestampStr: this.momentDate(clockInTimestamp, 'YYYY-MM-DD HH:mm'),
        updatedTimestamp: newClockInRequest.clockInTimestamp,
        timeData: {
          HH: newClockInRequest.clockInTimeData.HH,
          mm: newClockInRequest.clockInTimeData.mm
        },
        type: 'IN',
        allowance: newClockInRequest.project?.allowance,
        clockInItems: newClockInType === 'maintenance' ? newClockInRequest.clockInItemsMaintenance.filter(ci => ci.vehicleCode) : [],
        layoff: newClockInRequest.layoff,
        maintenance: newClockInType === 'maintenance',
        milling: newClockInRequest.milling,
        vehicleCode: newClockInRequest.vehicleCode,
        van: newClockInRequest.van,
        vanCode: newClockInRequest.vanCode,
        referenceDate: newClockInRequest.referenceDate,
        preparation: newClockInRequest.preparation,
        transfer: newClockInType === 'transfer'
      }

      const clockInDate = resIn.data.saveClockInAdminRequest.originalTimestampStr.substring(0, 10)

      let newClockOut
      let clockOutDate
      if (newClockInRequest.clockOutTimestamp) {
        var resOut = await this.$apollo
          .mutate({
            mutation: saveClockInAdminRequestMutation,
            variables: { clockInRequest: clockOutRequest }
          })
          .catch(error => {
            this.handleError(error)
          })

        newClockOut = {
          geolocation: '',
          id: resOut.data.saveClockInAdminRequest.id,
          project: newClockInRequest.project,
          part: newClockInRequest.part,
          subpart: newClockInRequest.subpart,
          originalTimestampStr: this.momentDate(clockOutTimestamp, 'YYYY-MM-DD HH:mm'),
          updatedTimestampStr: this.momentDate(clockOutTimestamp, 'YYYY-MM-DD HH:mm'),
          updatedTimestamp: newClockInRequest.clockOutTimestamp,
          timeData: {
            HH: newClockInRequest.clockOutTimeData.HH,
            mm: newClockInRequest.clockOutTimeData.mm
          },
          type: 'OUT',
          allowance: newClockInRequest.project?.allowance,
          clockInItems: newClockInRequest.clockInItemsRefuel.filter(ci => ci.vehicleCode) || [],
          layoff: newClockInRequest.layoff,
          maintenance: newClockInType === 'maintenance',
          milling: newClockInRequest.milling,
          millingHours: resOut.data.saveClockInAdminRequest.millingHours,
          vehicleCode: newClockInRequest.vehicleCode,
          lunchTime: newClockInRequest.lunchTime,
          van: newClockInRequest.van,
          vanCode: newClockInRequest.vanCode,
          referenceDate: newClockInRequest.referenceDate,
          preparation: newClockInRequest.preparation,
          preparationHours: resOut.data.saveClockInAdminRequest.preparationHours,
          transfer: newClockInType === 'transfer',
          minutesWorked: resOut.data.saveClockInAdminRequest.minutesWorked
        }

        clockOutDate = resOut.data.saveClockInAdminRequest.originalTimestampStr.substring(0, 10)
      }

      if (newClockInRequest.referenceDate == this.dataSource.date) {
        const newClockInPair = {
          clockIn: newClockIn,
          clockOut: newClockOut
            ? newClockOut
            : {
              timeData: {
                HH: '00',
                mm: '00'
              }
            },
          id: resIn.data.saveClockInAdminRequest.id,
          employee: resIn.data.saveClockInAdminRequest.employee,
          confirmed: this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED,
          loading: false,
          edit: false,
          openMenu: false,
          search: this.getProjectKeyCodeFromClockIn(newClockInRequest),
          subpart: newClockInRequest.subpart,
          layoff: newClockInRequest.layoff,
          maintenance: newClockInType === 'maintenance',
          milling: newClockInRequest.milling,
          millingHours: resOut ? resOut.data.saveClockInAdminRequest.millingHours : null,
          vehicleCode: newClockInRequest.vehicleCode,
          lunchTime: newClockInRequest.lunchTime,
          nextDayClockOut: newClockOut ? clockOutDate > clockInDate : false,
          van: newClockInRequest.van,
          vanCode: newClockInRequest.vanCode,
          referenceDate: newClockInRequest.referenceDate,
          preparation: newClockInRequest.preparation,
          preparationHours: resOut ? resOut.data.saveClockInAdminRequest.preparationHours : null,
          transfer: newClockInType === 'transfer'
        }

        if (this.order === 'projectCategory') {
          this.refetchProjectRow(this.dataSource.project.id)
        } else {
          this.$emit('new-clockin-pair-saved', this.dataSource.name, newClockInPair)
          await this.updateWorkHours()
        }

        //clear dialog project filter
        this.$emit('set-project-list-filter', { value: '', property: 'rowDialogProjectOptions' })
        this.$emit('set-project-category-list-filter', { value: '', property: 'rowDialogCategoryOptions' })
        this.$emit('set-employee-list-filter', { value: '', property: 'rowDialogEmployeeOptions' })
      }

      this.newClockInLoader = false
      this.clockInDialog = false
      await this.updateMaxBreakTime()
    },

    async deleteClockInPair(clockInPair) {
      clockInPair.loading = true

      const clockInId = clockInPair.clockIn.id
      const clockOutId = clockInPair.clockOut.id
      await this.$apollo.mutate({
        mutation: deleteClockInAndOutMutation,
        variables: { clockInId, clockOutId }
      })

      if (this.order !== 'projectCategory') {
        this.$emit('clockin-pair-deleted', this.source.name, clockInPair)
        await this.updateWorkHours()
      } else {
        this.refetchProjectRow(clockInPair.clockIn.project.id)
      }

      clockInPair.loading = false
      clockInPair.edit = false
      await this.updateMaxBreakTime()
    },

    async deleteClockOutFromClockInPair(clockInPair) {
      clockInPair.loading = true
      await this.$apollo.mutate({
        mutation: deleteClockInMutation,
        variables: { clockInId: clockInPair.clockOut.id }
      })
      clockInPair.clockOut = {
        allowance: clockInPair.clockIn.allowance,
        clockInItems: [],
        confirmed: this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED,
        geolocation: null,
        id: null,
        layoff: false,
        lunchTime: null,
        part: null,
        type: 'OUT',
        timeData: {
          HH: '00',
          mm: '00'
        }
      }

      if (this.order !== 'projectCategory') {
        this.$emit('clockin-pair-updated', this.source.name, clockInPair)
        await this.updateWorkHours()
      } else {
        this.refetchProjectRow(clockInPair.clockIn.project.id)
      }

      clockInPair.loading = false
      clockInPair.edit = false
    },

    projectParts(clockIn) {
      return clockIn?.project?.parts
    },

    projectPartValue(clockIn) {
      return clockIn?.part?.keyCode || '-'
    },

    changeProject(clockIn, value) {
      clockIn.clockIn.allowance = value?.project?.allowance ? value.project.allowance : value.allowance
      clockIn.clockIn.project = value?.project ? value.project : value
      clockIn.clockIn.part = null
      clockIn.search = value.keyCode
      clockIn.openMenu = false
    },

    chipColor(type) {
      if (type.toLowerCase() === 'in') return 'green'
      return 'red'
    },

    displayMap(project, employeeInLocation, employeeOutLocation, clickOn) {
      this.clockInMap = {
        showDialog: true,
        projectName: project?.label,
        projectLocation: project && project.latitude && project.longitude
          ? `{ "latitude": "${project.latitude}", "longitude": "${project.longitude}" }`
          : null,
        employeeName: this.sourceCopy.name,
        employeeInLocation: employeeInLocation,
        employeeOutLocation: employeeOutLocation,
        clickOn: clickOn
      }
    },

    getProjectKeyCodeFromClockIn(clockIn) {
      if (clockIn?.project?.keyCode) return clockIn.project.keyCode
      else return ''
    },

    onCancel(clockIn) {
      this.dataSource = cloneDeep(this.sourceCopy)
      clockIn.edit = false
    },

    restrictHours(clockIn) {
      const clockInTime = clockIn.clockIn.timeData.HH
      const range = []
      const max = Number(clockInTime)
      for (let i = 0; i < 24 - max; i++) range.push(i + max)
      return range
    },

    restrictInHours(clockIn, index) {
      const clockInsLength = this.computedClockIns.length

      const lastClockIn = index === clockInsLength - 1

      const hoursArray = Array.from(Array(24).keys())
      if (clockInsLength === 1 || index === 0) {
        if (clockIn.nextDayClockOut || !clockIn?.clockIn?.id || !clockIn?.clockOut?.id) return hoursArray
        const clockInTime = clockIn.clockOut.timeData.HH
        const range = []
        const max = Number(clockInTime)

        if (max === 0) return hoursArray

        for (let i = 0; i <= max; i++) range.push(i)
        return range
      } else {
        const previousClockIn = this.computedClockIns[index - 1]

        const minHour = Number(previousClockIn.clockOut.timeData.HH)
        let maxHour = 23

        if (!lastClockIn) maxHour = Number(clockIn.clockOut.timeData.HH)

        const range = []

        for (let i = minHour; i <= maxHour; i++) range.push(i)
        return range
      }
    },

    restrictOutHours(clockIn, index) {
      const clockInsLength = this.computedClockIns.length
      const hasNextClockin = index < clockInsLength - 1
      const clockInTime = Number(clockIn.clockIn.timeData.HH)
      const clockInMinutes = Number(clockIn.clockIn.timeData.mm)
      const min = clockInMinutes >= 30 ? clockInTime + 1 : clockInTime
      const hoursArray = Array.from(Array(24).keys())

      if (clockInsLength <= 1) {
        return hoursArray
      } else {
        let maxHour = 23
        if (hasNextClockin) {
          const nextClockIn = this.computedClockIns[index + 1]
          maxHour = Number(nextClockIn.clockIn.timeData.HH)
          const range = []

          for (let i = min; i <= maxHour; i++) range.push(i)
          return range
        }

        return hoursArray
      }
    },

    restrictMinutes(clockIn) {
      const range = []
      const clockInMaxHours = Number(clockIn.clockIn.timeData.HH)
      const clockOutHours = Number(clockIn.clockOut.timeData.HH)
      let maxMinutes = 60
      if (clockInMaxHours === clockOutHours) {
        maxMinutes = Number(clockIn.clockIn.timeData.mm)
      } else if (clockOutHours > clockInMaxHours) {
        maxMinutes = 0
      }
      for (let i = 0; i < 60 - maxMinutes; i++) range.push(i + maxMinutes)
      return range
    },

    restrictInMinutes(clockIn) {
      const currentClockInHR = Number(clockIn.clockIn.timeData.HH)
      const currentClockOutHR = Number(clockIn.clockOut.timeData.HH)
      const currentClockOutMin = Number(clockIn.clockOut.timeData.mm)

      if (currentClockInHR === currentClockOutHR) {
        const range = []
        if (currentClockOutMin === 0) return [0]
        for (let i = currentClockOutMin; i < 60; i++) range.push(i)
        return range
      }

      return Array.from(Array(61).keys())
    },

    restrictOutMinutes(clockIn, index) {
      const clockInsLength = this.computedClockIns.length
      const hasNextClockin = index < clockInsLength - 1

      const currentClockInHR = Number(clockIn.clockIn.timeData.HH)
      const currentClockOutHR = Number(clockIn.clockOut.timeData.HH)

      if (clockInsLength <= 1) {
        return Array.from(Array(61).keys())
      } else {
        if (hasNextClockin) {
          const nextClockIn = this.computedClockIns[index + 1]
          const nextClockInHR = Number(nextClockIn.clockIn.timeData.HH)
          const nextClockInMin = Number(nextClockIn.clockIn.timeData.mm)

          if (nextClockInHR === currentClockOutHR) {
            const range = []
            for (let i = 0; i < nextClockInMin; i++) range.push(i)
            return range
          }
        } else if (currentClockInHR === currentClockOutHR) {
          let i = Number(clockIn.clockIn.timeData.mm)

          if (i >= 30) return []
          const range = []
          for (i; i < 60; i++) range.push(i)
          return range
        }

        return Array.from(Array(61).keys())
      }
    },

    isSaveRowButtonDisabled(clockIn) {
      const isLunchTimeMissing = clockIn?.lunchTime === null
      if (isLunchTimeMissing && this.possibleQuestions.includes(4) && !clockIn.transfer) return true

      if (!clockIn.clockIn.updatedTimestamp || !this.rules.isTimeFormat(clockIn.clockIn.updatedTimestamp) || (clockIn.clockOut.updatedTimestamp && !this.rules.isTimeFormat(clockIn.clockOut.updatedTimestamp))) return true

      if (this.areClockInItemsRefuelIncompleted(clockIn) || this.areClockInItemsMaintenanceIncompleted(clockIn) || this.areClockInVehiclesIncompleted(clockIn)) return true

      const missingProject = !clockIn?.clockIn?.project ? true : false
      if (missingProject && !clockIn.maintenance && !clockIn.transfer) return true

      const clockInHours = Number(clockIn.clockIn.timeData.HH)
      const lateShift = clockInHours >= 18

      // if clockIn is larger than 18 return false to prevent any issues on the out
      if (lateShift) return false

      const clockOutHours = Number(clockIn.clockOut.timeData.HH)
      const clockInMinutes = Number(clockIn.clockIn.timeData.mm)
      const clockOutMinutes = Number(clockIn.clockOut.timeData.mm)

      if (clockInHours === clockOutHours) {
        return clockOutMinutes < clockInMinutes
      }

      if (clockIn.confirmed) {
        return true
      }

      return false
    },

    async saveRow(row) {
      try {
        row.loading = true

        //SAVE/UPDATE IN
        row.clockIn.timeData.HH = row.clockIn.updatedTimestamp[0] + row.clockIn.updatedTimestamp[1]
        row.clockIn.timeData.mm = row.clockIn.updatedTimestamp[3] + row.clockIn.updatedTimestamp[4]
        const clockInTime = Number(row.clockIn.timeData.HH)

        const timeStampClockIn = moment.utc(row.clockIn.updatedTimestampStr ?? this.source.date)
        timeStampClockIn.set('hour', Number(row.clockIn.timeData.HH))
        timeStampClockIn.set('minute', Number(row.clockIn.timeData.mm))

        //if the clockIn does not already exist, save as new, else update
        if (!row.clockIn.id || row.clockIn.id < 0) {
          const newClockInRequest = {
            employeeId: row.clockIn.employee.id,
            project: row.clockIn?.project ? { id: row.clockIn.project?.id } : null,
            part: row.clockIn?.part?.id ? { id: row.clockIn?.part?.id } : null,
            originalTimestampStr: this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm'),
            type: 'IN',
            maintenance: row.maintenance,
            vehicleCode: row.vehicleCode,
            driver: row.driver,
            confirmed: !row?.confirmed ? this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED : this.confirmTypeBasedOnCapabilities,
            subpart: row.subpart,
            van: row.van,
            vanCode: row.vanCode,
            milling: row.milling,
            millingHours: row.milling ? row.millingHours : null,
            clockInItems: [],
            allowance: row.clockIn.allowance ? row.clockIn.allowance : null,
            layoff: row.layoff,
            referenceDateStr: row.referenceDate,
            notes: row.clockIn.notes,
            preparation: row.preparation,
            preparationHours: row.preparation ? row.preparationHours : null,
            transfer: row.transfer,
            jobDescription: row.clockIn.jobDescription
          }

          if (!row.maintenance && !row.transfer) {
            if (row.clockIn.project) {
              newClockInRequest.project = { id: row.clockIn.project.id }
              if (row.clockIn.part) {
                newClockInRequest.part = { id: row.clockIn.part.id }
              } else {
                newClockInRequest.part = null
              }
            }
          } else if (row.maintenance) {
            newClockInRequest.clockInItems = row.clockIn.clockInItems.map(ci => ({
              vehicleCode: ci.vehicleCode,
              quantity: ci.quantity,
              hour: 0,
              id: null
            }))
          }

          const resIn = await this.$apollo
            .mutate({
              mutation: saveClockInAdminRequestMutation,
              variables: { clockInRequest: newClockInRequest }
            })
            .catch(error => {
              this.handleError(error)
            })
          row.id = resIn?.data?.saveClockInAdminRequest.id
          row.clockIn.id = resIn?.data?.saveClockInAdminRequest.id
          row.clockIn.part = resIn?.data?.saveClockInAdminRequest?.part
          row.subpart = resIn?.data?.saveClockInAdminRequest?.subpart
          row.clockIn.confirmed = resIn?.data?.saveClockInAdmingRequest?.confirmed
        } else {
          const clockInUpdateRequestObject = {
            id: row.clockIn.id,
            updatedTimestampStr: this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm'),
            allowance: row.clockIn.allowance ? row.clockIn.allowance : null,
            layoff: row.layoff,
            subpart: row.subpart,
            driver: row.driver,
            van: row.van,
            vanCode: row.vanCode,
            confirmed: !row?.confirmed ? this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED : this.confirmTypeBasedOnCapabilities,
            maintenance: row.maintenance,
            vehicleCode: row.vehicleCode ? row.vehicleCode : null,
            milling: row.milling,
            millingHours: row.milling ? row.millingHours : null,
            clockInItems: [],
            referenceDateStr: row.referenceDate,
            dpiVerification: row.dpiVerification,
            manHolesVerification: row.manHolesVerification,
            materialUsed: row.materialUsed,
            asphaltTemperature: row.asphaltTemperature,
            environmentTemperature: row.environmentTemperature,
            notes: row.clockIn.notes,
            preparation: row.preparation,
            preparationHours: row.preparation ? row.preparationHours : null,
            transfer: row.transfer,
            jobDescription: row.clockIn.jobDescription
          }

          if (!row.maintenance && !row.transfer) {
            if (row.clockIn.project?.id) {
              clockInUpdateRequestObject.project = {
                id: row.clockIn.project.id
              }
              if (row.clockIn.part?.id) {
                clockInUpdateRequestObject.part = { id: row.clockIn.part.id }
              } else {
                clockInUpdateRequestObject.part = null
              }
            }
          } else if (row.maintenance) {
            clockInUpdateRequestObject.clockInItems = row.clockIn.clockInItems.map(ci => ({
              vehicleCode: ci.vehicleCode,
              quantity: ci.quantity,
              hour: 0,
              id: null
            }))
          }

          const resIn = await this.$apollo
            .mutate({
              mutation: updateOneClockInMutation,
              variables: {
                clockInUpdateRequestObject
              }
            })
            .catch(error => {
              this.handleError(error)
            })
          row.clockIn.part = resIn?.data?.updateOneClockIn?.part
          row.subpart = resIn?.data?.updateOneClockIn?.subpart
          row.clockIn.project = resIn?.data?.updateOneClockIn?.project
          row.clockIn.allowance = resIn?.data?.updateOneClockIn?.allowance
          row.clockIn.confirmed = resIn?.data?.updateOneClockIn?.confirmed
        }

        //SAVE/UPDATE OUT
        row.clockIn.updatedTimestampStr = this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm')

        if (row.clockOut.updatedTimestamp) {
          row.clockOut.timeData.HH = row.clockOut.updatedTimestamp[0] + row.clockOut.updatedTimestamp[1]
          row.clockOut.timeData.mm = row.clockOut.updatedTimestamp[3] + row.clockOut.updatedTimestamp[4]
          const clockOutTime = Number(row.clockOut.timeData.HH)

          let dateOut = clockOutTime < clockInTime
            ? moment(row.clockIn.updatedTimestampStr).add(1, 'days').format('YYYY-MM-DD')
            : row.clockIn.updatedTimestampStr

          const timeStampClockOut = moment.utc(dateOut)
          timeStampClockOut.set('hour', Number(row.clockOut.timeData.HH))
          timeStampClockOut.set('minute', Number(row.clockOut.timeData.mm))

          //if the clockOut does not already exist, save as new, else update
          if (!row.clockOut.id || row.clockOut.id < 0) {
            const newClockOutRequest = {
              employeeId: row.clockIn.employee.id,
              project: row.clockIn?.project?.id ? { id: row.clockIn.project.id } : null,
              part: row.clockIn?.part?.id ? { id: row.clockIn?.part?.id } : null,
              originalTimestampStr: this.momentDate(timeStampClockOut, 'YYYY-MM-DD HH:mm'),
              type: 'OUT',
              maintenance: row.maintenance,
              vehicleCode: row.vehicleCode,
              driver: row.driver,
              confirmed: !row?.confirmed ? this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED : this.confirmTypeBasedOnCapabilities,
              subpart: row.subpart,
              van: row.van,
              vanCode: row.vanCode,
              milling: row.milling,
              millingHours: row.milling ? row.millingHours : null,
              boots: row.boots,
              bootsHours: row.boots ? row.bootsHours : null,
              demolitionMan: row.demolitionMan,
              demolitionManHours: row.demolitionMan ? row.demolitionManHours : null,
              confinedSpaces: row.confinedSpaces,
              confinedSpacesHours: row.confinedSpaces ? row.confinedSpacesHours : null,
              deepExcavations: row.deepExcavations,
              deepExcavationsHours: row.deepExcavations ? row.deepExcavationsHours : null,
              driving: row.driving,
              drivingHours: row.driving ? row.drivingHours : null,
              clockInItems: row.clockOut.clockInItems
                ? row.clockOut.clockInItems?.map(ci => ({
                  vehicleCode: ci.vehicleCode,
                  quantity: ci.quantity,
                  hour: 0,
                  id: null
                }))
                : [],
              clockInVehicles: row.clockOut.clockInVehicles
                ? row.clockOut.clockInVehicles?.map(civ => ({
                  hours: civ.hours,
                  vehicle: {
                    id: civ.vehicle.id,
                    code: civ.vehicle.code
                  }
                }))
                : [],
              allowance: row.clockIn?.project?.allowance || row?.clockIn?.allowance || null,
              layoff: row.layoff,
              notes: row.notes,
              referenceDateStr: row.referenceDate,
              destSame: true,
              preparation: row.preparation,
              preparationHours: row.preparation ? row.preparationHours : null,
              transfer: row.transfer,
              geoReferenced: row.clockIn?.project?.id ? 'CONFIRMED_BY_ADMINISTRATOR' : null,
              jobDescription: row.jobDescription
            }

            if (row.lunchTime || row.lunchTime >= 0) newClockOutRequest.lunchTime = row.lunchTime

            const resOut = await this.$apollo
              .mutate({
                mutation: saveClockInAdminRequestMutation,
                variables: { clockInRequest: newClockOutRequest }
              })
              .catch(error => {
                this.handleError(error)
              })

            row.clockOut.confirmed = resOut.data.saveClockInAdminRequest.confirmed
            row.clockOut.clockInItems = []
            row.clockOut.id = resOut.data.saveClockInAdminRequest.id
            row.clockOut.type = 'OUT'
            row.clockOut.minutesWorked = resOut.data.saveClockInAdminRequest.minutesWorked
            row.clockOut.originalTimestampStr = resOut.data.saveClockInAdminRequest.originalTimestampStr
          } else {
            const clockOutUpdateRequestObject = {
              id: row.clockOut.id,
              updatedTimestampStr: this.momentDate(timeStampClockOut, 'YYYY-MM-DD HH:mm'),
              allowance: row.clockIn.allowance ? row.clockIn.allowance : null,
              layoff: row.layoff,
              subpart: row.subpart,
              driver: row.driver,
              van: row.van,
              vanCode: row.vanCode,
              confirmed: !row?.confirmed ? this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED : this.confirmTypeBasedOnCapabilities,
              maintenance: row.maintenance,
              vehicleCode: row.vehicleCode ? row.vehicleCode : null,
              milling: row.milling,
              millingHours: row.milling ? row.millingHours : null,
              boots: row.boots,
              bootsHours: row.boots ? row.bootsHours : null,
              demolitionMan: row.demolitionMan,
              demolitionManHours: row.demolitionMan ? row.demolitionManHours : null,
              confinedSpaces: row.confinedSpaces,
              confinedSpacesHours: row.confinedSpaces ? row.confinedSpacesHours : null,
              deepExcavations: row.deepExcavations,
              deepExcavationsHours: row.deepExcavations ? row.deepExcavationsHours : null,
              driving: row.driving,
              drivingHours: row.driving ? row.drivingHours : null,
              clockInItems: row.clockOut.clockInItems
                ? row.clockOut.clockInItems.map(ci => ({
                  vehicleCode: ci.vehicleCode,
                  quantity: ci.quantity,
                  hour: 0,
                  id: null
                }))
                : [],
              clockInVehicles: row.clockOut.clockInVehicles
                ? row.clockOut.clockInVehicles?.map(civ => ({
                  hours: civ.hours,
                  vehicle: {
                    id: civ.vehicle.id,
                    code: civ.vehicle.code
                  }
                }))
                : [],
              notes: row.notes,
              referenceDateStr: row.referenceDate,
              dpiVerification: row.dpiVerification,
              manHolesVerification: row.manHolesVerification,
              materialUsed: row.materialUsed,
              asphaltTemperature: row.asphaltTemperature,
              environmentTemperature: row.environmentTemperature,
              securityType: row.securityType && row.securityType.length !== 0 ? JSON.stringify(row.securityType) : null,
              securityNote: row.securityNote && row.securityNote.length ? row.securityNote : null,
              destSame: true,
              preparation: row.preparation,
              preparationHours: row.preparation ? row.preparationHours : null,
              transfer: row.transfer,
              geoReferenced: row.clockIn?.project?.id ? 'CONFIRMED_BY_ADMINISTRATOR' : null,
              jobDescription: row.jobDescription
            }

            if (row.lunchTime || row.lunchTime >= 0) clockOutUpdateRequestObject.lunchTime = row.lunchTime

            if (!row.maintenance && !row.transfer) {
              if (row.clockIn.project) {
                clockOutUpdateRequestObject.project = {
                  id: row.clockIn.project.id
                }
                if (row.clockIn.part?.id) {
                  clockOutUpdateRequestObject.part = {
                    id: row.clockIn.part.id
                  }
                } else {
                  clockOutUpdateRequestObject.part = null
                }
              }
            }

            if (row.lunchTime || row.lunchTime >= 0) clockOutUpdateRequestObject.lunchTime = row.lunchTime

            const resOut = await this.$apollo
              .mutate({
                mutation: updateOneClockInMutation,
                variables: {
                  clockInUpdateRequestObject: clockOutUpdateRequestObject
                }
              })
              .catch(error => {
                this.handleError(error)
              })
            row.clockOut.confirmed = resOut.data.updateOneClockIn.confirmed
            row.clockOut.minutesWorked = resOut.data.updateOneClockIn.minutesWorked
          }

          row.clockOut.updatedTimestampStr = this.momentDate(timeStampClockOut, 'YYYY-MM-DD HH:mm')
        } else if (row.clockOut.id && row.clockOut.id < 0) {
          await this.deleteClockOutFromClockInPair(row)
        }

        row.edit = false
        this.showSuccess = true
        this.showError = false
        //this.dataSource.clockIns[i] = row;
        //this.sourceCopy = cloneDeep(this.dataSource);
        this.forceUpdateLabel++
        row.loading = false
        //eventBus.updateClockIn(this.dataSource);
        if (this.order !== 'projectCategory') {
          this.$emit('clockin-pair-updated', this.source.name, row)
          await this.updateWorkHours()
          await this.updateMaxBreakTime()
        } else {
          this.refetchProjectRow(this.dataSource.project.id)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        row.loading = false
        this.showSuccess = false
        this.showError = true
      }
    },

    async confirmClockInPair(clockInPair, value) {
      clockInPair.confirmed = value
      await this.saveRow(clockInPair)
    },

    async confirmAllClockInPairs() {
      for (let ci of this.computedClockIns) {
        if (ci.id > 0) {
          await this.confirmClockInPair(ci, true)
        }
      }
    },

    onClockInItemHourChange(item, hour) {
      if (hour % 1 < 0.5) {
        item.hour = Math.floor(hour)
      } else {
        item.hour = Math.floor(hour) + 0.5
      }
    },

    openNewClockInHandler() {
      this.clockInDialog = true
    },

    startEditingClockIn(e, clockIn) {
      if (!clockIn.confirmed && (e.target.className.includes ? !e.target.className.includes('mapLink') : true)) {
        clockIn.edit = true
        clockIn.search = this.getProjectKeyCodeFromClockIn(clockIn.clockIn)

        if (clockIn.openMenu) {
          this.closeMenuHandler(clockIn)
        }
      }
    },

    getColor(status) {
      const colors = {
        green: {
          bg: '#FCFCFC',
          text: '#2A6A2D'
        },
        red: {
          bg: '#FCFCFC',
          text: '#0661FF'
        }
      }
      if (status) return colors.green
      return colors.red
    },

    async updateWorkHours() {
      const employeeId = this.sourceCopy.id
      const date = this.sourceCopy.date
      const res = await this.$apollo.query({
        query: workHoursQuery,
        variables: {
          employeeId: employeeId,
          dateStr: date
        },
        fetchPolicy: 'network-only'
      })
      const workHours = res.data.workHours
      this.sourceCopy.workHours = workHours
    },

    areClockInItemsRefuelIncompleted(clockInPair) {
      return clockInPair?.clockOut?.clockInItems?.some(item => !item.vehicleCode || item.quantity <= 0)
    },

    areClockInVehiclesIncompleted(clockInPair) {
      return clockInPair?.clockOut?.clockInVehicles?.some(item => !item.vehicle?.code || item.hours <= 0)
    },

    areClockInItemsMaintenanceIncompleted(clockInPair) {
      return clockInPair?.clockIn?.clockInItems?.some(item => !item.vehicleCode)
    },

    referenceDateOptions(date) {
      const currentDayDate = moment(date)
      const previousDayDate = moment(date).subtract(1, 'days')
      const nextDayDate = moment(date).add(1, 'days')

      return [
        {
          value: previousDayDate.format('yyyy-MM-DD'),
          text: previousDayDate.format('DD/MM/yyyy')
        },
        {
          value: currentDayDate.format('yyyy-MM-DD'),
          text: currentDayDate.format('DD/MM/yyyy')
        },
        {
          value: nextDayDate.format('yyyy-MM-DD'),
          text: nextDayDate.format('DD/MM/yyyy')
        }
      ]
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.activeMenuList === 'projects') {
          return this.$emit('fetch-more-projects', 'rowProjectsOption')
        } else {
          return this.$emit('fetch-more-categories', 'rowCategoryOptions')
        }
      }
    },

    closeHandler() {
      this.clockInDialog = false
      this.$emit('set-project-list-filter', { value: '', property: 'rowDialogProjectOptions' })
      this.$emit('set-project-category-list-filter', { value: '', property: 'rowDialogCategoryOptions' })
      this.$emit('set-employee-list-filter', { value: '', property: 'rowDialogEmployeeOptions' })
    },

    inputHandler(value) {
      this.$emit('set-project-list-filter', { value, property: 'rowProjectsOption' })
      this.$emit('set-project-category-list-filter', { value, property: 'rowCategoryOptions' })
    },

    closeMenuHandler(clockIn) {
      clockIn.search = this.getProjectKeyCodeFromClockIn(clockIn.clockIn)
      clockIn.openMenu = false
      this.$emit('set-project-list-filter', { value: '', property: 'rowProjectsOption' })
      this.$emit('set-project-category-list-filter', { value: '', property: 'rowCategoryOptions' })
    },

    setClockInVehicle(val, index) {
      if (val) {
        const { code, label, id } = val
        this.selectedClockInPair.clockOut.clockInVehicles[index].vehicle = { code, id, label }
      }
    },

    formatEmployeeName(name) {
      let fullname = name.split(' ')
      return fullname[0] + ' ' + fullname[1].substring(0, 3) + '.'
    },

    refetchProjectRow(projectId) {
      this.$emit('refetch-project-row', projectId)
    },

    closeSwitchHourDialogHandler() {
      if (this.missingTime) return
      this.dialogType = ''
      this.dialogTitle = ''
      this.dialogTypeHours = ''
    },

    showBreakDuration() {
      return this.lunchBreak.enabled
        && this.source.clockIns.length > 0 && this.source.clockIns.filter(item => item.id > 0).length > 1
        && this.maxBreakTime < this.lunchBreak.threshold
    },

    async updateMaxBreakTime() {
      const res = await this.$apollo.query({
        query: updateMaxBreakTime,
        variables: {
          employeeId: this.sourceCopy.id,
          dateStr: this.sourceCopy.date
        },
        fetchPolicy: 'network-only'
      })

      this.maxBreakTime = res.data.updateMaxBreakTime.maxBreakTime

      if (res.data.updateMaxBreakTime.breakTimes) {
        for (let [key, value] of new Map(Object.entries(res.data.updateMaxBreakTime.breakTimes))) {
          this.dataSource.clockIns[key].breakTime = value
        }
      }
    },

    async adjustBreakTime(row) {
      let updatedTime = this.addMinutes(row.clockIn.updatedTimestamp, (this.lunchBreak.threshold - row.breakTime))
      let hh = updatedTime[0] + updatedTime[1]
      let nextDay = false

      // check if updated time is in next day
      if (Number(row.clockIn.updatedTimestamp[0] + row.clockIn.updatedTimestamp[1]) > hh) {
        nextDay = true
      }

      // if clockOut doesn't exist update clockIn time
      if (!row.clockOut.id || row.clockOut.id < 0) {
        row.clockIn.updatedTimestamp = updatedTime

        // update clockIn timestamp if date changes
        if (nextDay) {
          const timeStampClockIn = moment.utc(moment(row.clockIn.updatedTimestampStr).add(1, 'days').format('YYYY-MM-DD') + (' ' + updatedTime))
          row.clockIn.updatedTimestampStr = this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm')
        }
        await this.saveRow(row)

        // if clockOut exists check if update is possible
      } else if (row.clockOut.id && row.clockOut.id > 0) {
        // get clockIn time
        const timeStampClockIn = moment.utc((nextDay
            ? moment(row.clockIn.updatedTimestampStr).add(1, 'days').format('YYYY-MM-DD')
            : this.formatDate(row.clockIn.updatedTimestampStr))
          + (' ' + updatedTime))

        // get clockOut time
        const timeStampClockOut = moment.utc(row.clockOut.updatedTimestampStr)

        // check if updated time exceeds clockOut time
        if (timeStampClockIn.isSameOrBefore(timeStampClockOut)) {
          row.clockIn.updatedTimestamp = updatedTime

          // update clockIn timestamp if date changes
          row.clockIn.updatedTimestampStr = nextDay ? this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm') : row.clockIn.updatedTimestampStr
          await this.saveRow(row)
        } else {
          this.handleError(this.translations.breakTimeError)
        }
      }
    }
  },

  computed: {
    ...mapState({
      clockInsDate: state => state.clockIns.clockInsDate
    }),

    icons: () => ({
      mdiChevronRight,
      mdiChevronLeft,
      mdiSquareEditOutline,
      mdiContentSaveOutline,
      mdiCancel,
      mdiClose,
      mdiPlus,
      mdiTrashCanOutline,
      mdiCheckCircleOutline,
      mdiCheck,
      mdiWeatherNight
    }),

    translations: () => translations,

    defaultProject() {
      return this.dataSource?.clockIns?.[0]?.clockIn?.project?.label || ''
    },

    computedClockIns() {
      if (!this.dataSource?.clockIns?.length) return []
      else if (this.dataSource?.clockIns.length && this.order !== 'projectCategory') {
        return this.dataSource.clockIns.filter(clockIn => clockIn.id > 0)
      } else return this.dataSource.clockIns
    },

    hasLunchTime() {
      const hasTime = e => e.lunchTime !== null && e.lunchTime >= 0

      return this.orderBy !== 'projectCategory' ? this.computedClockIns.some(hasTime) : false
    },

    allClockInsAreConfirmed() {
      return this.computedClockIns.every(ci => ci.confirmed == true)
    },

    newClockInRangeHours() {
      if (!this.computedClockIns?.length) return Array.from(Array(24).keys())

      if (this.computedClockIns?.length === 1) {
        const { clockIn, clockOut } = this.computedClockIns[0]
        const inHr = Number(clockIn?.timeData?.HH)
        const outHr = Number(clockOut?.timeData?.HH)

        if (inHr === 0 && outHr === 0) return Array.from(Array(24).keys())
      }
      const times = []
      const range = []

      this.computedClockIns.forEach(clockInObj => {
        const { clockIn, clockOut } = clockInObj
        const inHr = Number(clockIn.timeData.HH)
        const outHr = Number(clockOut.timeData.HH)

        const maxHr = outHr ? outHr : 24
        let i = inHr
        for (i; i < maxHr; i++) {
          times.push(i)
        }
      })

      for (let i = 0; i < 24; i++) {
        if (!times.includes(i)) range.push(i)
      }

      return range
    },

    lunchBreakOptions() {
      return getLunchTimes()
    },

    securityTypeOptions() {
      return getSecurityTypes()
    },

    resetAllowanceOnWeekend() {
      return getResetAllowanceOnWeekend()
    },

    clockInGeoRefIsEnabled() {
      return getClockInGeoRefIsEnabled()
    },

    showClockInTimeAlert() {
      return getShowClockInTimeAlert()
    },

    dateIsOnWeekend() {
      return this.selectedDate ? [0, 6].includes(this.momentToDate(this.selectedDate).getDay()) : false
    },

    mainColumn() {
      return this.orderBy === 'employee' ? this.dataSource.name : this.dataSource.project.keyCode
    },

    missingTime() {
      // if no selectedClockInPair or dialogType or property is false do not persist
      if (Object.keys(this.selectedClockInPair).length <= 0 || this.dialogType === '' || !this.selectedClockInPair[this.dialogType]) return false
      return this.selectedClockInPair[this.dialogTypeHours] <= 0
    },

    clockInTransferIsEnabled() {
      return getClockInTransferIsEnabled()
    }
  }
}
</script>

<style>
.virt-grid-row {
  display: grid;
  grid-template-columns: 200px 10fr;
  position: relative;
}

.report-row .v-text-field .v-input__control .v-input__slot {
  min-height: 25px !important;
  display: flex !important;
  align-items: center !important;
}

.report-row .v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 25px !important;
}

.report-row .v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 13px;
}

.clockin-row {
  align-items: center;
  display: grid;
}

.clockin-mid-grid-row {
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  display: grid;
}

.clockin-row > * {
  align-items: center;
  text-align: -webkit-center;
  height: 35px;
  padding-top: 5px;
  padding-left: 1px;
}

.clockin-row:hover {
  background-color: #f4f8ff;
}

div.layoff > div.v-input {
  margin: 0;
}

.wb-w100 {
  word-wrap: break-word;
  width: 170px;
}

.f-12 {
  font-size: 12px;
}

.v-input.v-input--hide-details.v-input--dense.theme--light.v-input--selection-controls.v-input--checkbox {
  margin-top: 0;
}

.notes-text {
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  max-width: 120px;
}

/* part select styling  */
.report-row .part-select .v-input__control .v-input__slot {
  padding: 0px 8px !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  flex-wrap: nowrap !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner {
  margin-top: 12px !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon .v-icon {
  font-size: 14px;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon {
  height: 18px !important;
  width: 18px !important;
  max-width: 18px !important;
  min-width: 18px !important;
}

.security-type-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
}

.security-type-container .checkbox {
  margin: 0;
  padding: 0;
}

.textarea {
  margin: 0;
  padding: 10px 0;
}

/* end part select styling  */

.clock-in-notes .v-input__slot {
  max-height: 30px !important;
}

.clock-in-notes textarea {
  width: 120px !important;
  max-height: 30px !important;
  font-size: 10px !important;
  line-height: 16px !important;
  resize: none !important;
  border-radius: 5px;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
